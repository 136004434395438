import './initials.css'

import React from 'react'

const Initials = (props) => {
  const initials = (name) => {
    let result = name?.split(' ').filter((_, i) => i < 2).map(part => part.charAt(0)).join('')
    return result
  }

  return (
    <div className='text-center d-flex justify-content-center'>
      <div style={{ borderRadius: '50%', backgroundColor: '#62CDFF', color: '#ffffff', width: 130, height: 130 }}>
        <div style={{ fontSize: '3em', paddingTop: '28px' }}>{initials(props.name)}</div>
      </div>
    </div>
  )
}

export default Initials