import { Box, Button, IconButton, InputAdornment, Paper, Snackbar, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { authenticate } from '../../services/employee-service';
import pageBg from '../../images/login-bg1.png';
import DishaFLEX from '../../images/dishaflex.png';
import Spinner from '../../components/spinner/spinner';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function Login() {
  const [workInProgress, setWorkInProgress] = useState(false);
  const navigate = useNavigate();
  const [authFailure, setAuthFailure] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = (formData) => {
    setWorkInProgress(true);
    authenticate(formData).then(result => {
      setWorkInProgress(false);
      if (result.data.data && result.data.data.authToken) {
        localStorage.setItem('authToken', result.data.data.authToken);
        navigate('/home');
      }
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setAuthFailure(true);
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const outerDivStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundImage: `url(${pageBg})`,
    backgroundSize: 'cover'
  };
  const paperStyle = {
    display: 'flex',
    flexDirection: 'row',
    margin: 10
  };
  const imgStyle = {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    height: '65vh'
  };
  const formDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  };
  const copyrightMessageStyle = { marginTop: 20, textAlign: 'center' };

  const action = (
    <React.Fragment>
      <Button style={{ textTransform: 'capitalize' }} color="secondary" size="small" onClick={(event) => setAuthFailure(false)}>Close</Button>
    </React.Fragment>
  );

  return (
    <div style={outerDivStyle}>
      {workInProgress ? <Spinner /> : <div></div>}
      <Paper style={paperStyle} elevation={3}>
        <div style={formDivStyle}>
          <div style={{ padding: 30 }}>
            <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <img src={DishaFLEX} style={{ height: '50px' }} />
            </Box>
            <Typography variant='h5'>Sign In</Typography>
            <div className='p-2'></div>
            <p>Please use your registered email and password to Sign in</p>
            <form>
              <div className='form-field'>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      style={{ width: '100%' }}
                      error={errors.email ? true : false}
                      variant="standard"
                      label="Email*"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='form-field'>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="password"
                  render={({ field }) => (
                    <TextField
                      style={{ width: '100%' }}
                      error={errors.password ? true : false}
                      variant="standard"
                      label="Password*"
                      type={showPassword ? 'text' : 'password'}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </div>
              <div style={{ paddingBottom: 10, paddingTop: '10px' }}>
                <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>Login</Button>
              </div>
            </form>
          </div>
        </div>
      </Paper>
      <div style={copyrightMessageStyle}><Typography>(C) 2023 SashaIndia. All Rights Reserved.</Typography></div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={authFailure}
        autoHideDuration={3000}
        message='Authentication Failed.'
        action={action}
        onClose={() => setAuthFailure(false)}
      />
    </div>
  );
}
