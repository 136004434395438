import axios from "axios";

const getLogoUrl = () => {
  return `${process.env.REACT_APP_API_ENDPOINT}/organization/logo?t=${localStorage.getItem('authToken')}`
}

const getOrganizationDetails = async(orgId) => {
  try {
    return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/organization/${orgId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });
  }
  catch(error) {
    console.log(error);
  }
}

export { getLogoUrl, getOrganizationDetails };