import './avatar-section.css'
import React, { useEffect, useState } from 'react'
import Initials from '../initials/initials'
import { getLogoUrl, getOrganizationDetails } from '../../services/org-service'
import jwtDecode from 'jwt-decode'

const AvatarSection = (props) => {
  let { userInfo } = props;
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    const fetchOrg = async() => {
      let decoded = jwtDecode(localStorage.getItem('authToken'));
      const org = await getOrganizationDetails(decoded.organization);
      setOrganization(org);
    }
    fetchOrg();
  }, []);

  return (
    <div>
      <div className='p-2'></div>
      <div className='d-flex justify-content-center'>
        <img src={getLogoUrl()} style={{height: '40px'}}/>
      </div>
      <div className='p-2'></div>
      <div className='p-2'></div>
      <Initials name={userInfo?.name} />
      <div className='p-2'></div>
      <div className='text-center'><h5 className='text-white'>{userInfo?.name}</h5></div>
      {organization?.showDesignation && <div className='text-center text-white'><small>{userInfo?.designation}</small></div>}
      <div className='p-2'></div>
    </div>
  )
}

export default AvatarSection